import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';

import useApiQuery from '../hooks/useApiQuery';
import { DataGrid, GridArrowDownwardIcon } from '@mui/x-data-grid';
import moment from 'moment';
import PrintButton from '../components/FormTable/PrintButton';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Toolbar,
} from '@mui/material';

import styled from '@emotion/styled';
import { getValid } from './App';
import TabPanel from 'src/components/TabPanel';
import AllPDFForms from 'src/components/PDFTable';
import { useJwt } from 'src/hooks/useJwt';
import DownloadButton from 'src/components/FormTable/DownloadButton';
import ContainerTable from 'src/components/ContainerTable';
import { useDownloadCsv } from 'src/hooks/useDownloadCsv';

const Styled__Wrap = styled.div`
  padding: 2%;
  margin: 0.5rem auto;
`;
export const STYLED_DATA_GRID = styled(DataGrid)`
  font-size: 1rem;
  background-color: #181818;
  opacity: 1 !important;
  && .MuiDataGrid-root {
    opacity: 1;
  }
  && .MuiDataGrid-columnHeaderTitle {
    font-weight: 800;
  }
  && .MuiDataGrid-columnHeader {
    background-color: #f8f8f8;
    color: black;
    font-weight: 800;

    & .MuiSvgIcon-root,
    & .MuiIconButton-root {
      color: black;
      opacity: 1;
    }
  }
  && .MuiDataGrid-iconButtonContainer {
    visibility: visible;
    width: auto;
    color: black;
    opacity: 1;
  }
`;

const AllForms = () => {
  const [entries, setEntries] = useState(null);

  const [tabValue, setTabValue] = useState(0);
  const { data, loading, error } = useApiQuery(
    { path: '/forms/all' },
    { revalidateOnFocus: true }
  );
  useJwt();

  const [handleCsv] = useDownloadCsv();
  useEffect(() => {
    if (error) {
      console.log({ error });
      if (error.status === 401) {
        // localStorage.clear();
      }
    }
    if (data && entries == null) {
      setEntries(data.data);
    }
  }, [error, data]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const changeFacility = (facilityName) => {
    if (data) {
      if (facilityName == 'All') {
        setEntries(data.data);
      } else {
        setEntries(
          data.data.filter((d) => d.CleanlitesFacility === facilityName)
        );
      }
    }
  };

  if (loading || !data || error || !entries) {
    return (
      <div style={{ padding: '1rem' }}>
        <LinearProgress />
      </div>
    );
  }
  return (
    <>
      <Styled__Wrap>
        <h2
          style={{
            padding: '1.5rem',
            fontSize: '32px',
            letterSpacing: '6px',
            fontWeight: '800',
            textTransform: 'uppercase',
            textAlign: 'center',
            width: '100%',
            color: 'white',
            marginBottom: '1rem',
          }}
        >
          All Order Forms
        </h2>
        <Toolbar disableGutters>
          <Box sx={{ display: 'flex', gap: '1rem', pb: 2, flexWrap: 'wrap' }}>
            <Autocomplete
              disablePortal
              id="generator"
              options={data.data.flatMap((d) => d.billingCompany)}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Biller" />}
            />
            <Autocomplete
              disablePortal
              id="generator"
              options={data.data.flatMap((d) => d.generatorCompany)}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Generator" />
              )}
            />
            <FormControl>
              <InputLabel id="criFacility">CRI Facility</InputLabel>
              <Select
                fullWidth
                labelId="criFacility"
                sx={{ width: 300 }}
                onChange={(e) => changeFacility(e.target.value)}
                placeholder="Filter CRI Facility"
                label="Filter Cleanlites Facility"
              >
                {[
                  'All',
                  'Minnesota',
                  'Cincinnati Anthony Wayne',
                  'Cincinnati Northland',
                  'Michigan',
                  'South Carolina',
                  'Not Sure',
                ].map((o) => (
                  <MenuItem key={o} value={o}>
                    {o}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button onClick={handleCsv}>Download All As CSV</Button>
          </Box>
        </Toolbar>

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label="Online Order" />
            <Tab label="PDF Uploads" />
            <Tab label="Container Orders" />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <div style={{ height: 300, width: '100%', minHeight: '800px' }}>
            <STYLED_DATA_GRID
              loading={loading}
              rows={entries
                .filter((e) => !e.isPdf)
                .filter((e) => !e.containerOnly)}
              columns={[
                {
                  field: 'submitted_at',
                  headerName: 'Submitted At',
                  width: 220,

                  renderCell: (params) => {
                    return moment
                      .utc(params.row.submitted_at)
                      .format('MM/DD/YY h:mma');
                  },
                },
                {
                  field: 'billingCompany',
                  headerName: 'Billing Company',
                  width: 220,
                },
                {
                  field: 'generatorCompany',
                  headerName: 'Generator',
                  width: 240,
                },
                {
                  field: 'CleanlitesFacility',
                  headerName: 'CRI Facility',
                  width: 200,
                  flex: 1,
                },
                // { field: 'poNumber', headerName: 'PO Number', width: 280 },
                {
                  field: 'view',
                  width: 90,
                  disableColumnMenu: true,
                  sortable: false,
                  filterable: false,
                  headerAlign: 'center',
                  renderHeader: () => <>View</>,
                  align: 'center',
                  renderCell: (params) => (
                    <Link to={`/forms/${params.row.id}`}>
                      <IconButton>
                        <RemoveRedEyeIcon color="secondary" />
                      </IconButton>
                    </Link>
                  ),
                },
                {
                  field: 'print',
                  headerName: 'Column 2',
                  renderHeader: () => <>Print</>,
                  headerAlign: 'center',
                  disableColumnMenu: true,
                  sortable: false,
                  filterable: false,
                  align: 'center',
                  width: 90,
                  renderCell: (params) => {
                    return <PrintButton uuid={params.row.id} />;
                  },
                },
                {
                  field: 'download',
                  width: 110,
                  disableColumnMenu: true,
                  sortable: false,
                  filterable: false,
                  headerAlign: 'center',
                  renderHeader: () => <>Download</>,
                  align: 'center',
                  renderCell: (params) => (
                    <DownloadButton params={params} isPdf={false} />
                  ),
                },
              ]}
            />
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <AllPDFForms entries={entries} loading={loading} />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <ContainerTable entries={entries} loading={loading} />
        </TabPanel>
      </Styled__Wrap>

      {/* <FormHeader
          form={{
            submitted_at: 'Submitted At',
            billingCompany: 'Billing Company',
            generatorCompany: 'Generator',
            CleanlitesFacility: 'Cleanlites Facility',
            poNumber: 'PO Number',
          }}
        />
        {values &&
          values.data.map((v) => <FormTable key={v.id} form={v} showButton />)} */}
    </>
  );
};

export default AllForms;
