import moment from 'moment';
import useApiQuery from './useApiQuery';

export const useDownloadCsv = () => {
  const handleClick = async (response) => {
    const blob = await response.blob();
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `order-form-report-${moment().format('MM-DD-yyyy')}.csv`;
    link.click();
    setTimeout(() => URL.revokeObjectURL(link.href), 0);
  };

  const handleFetch = () => {
    fetch('/api/forms/all-csv', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }).then((res) => handleClick(res));
  };

  return [handleFetch];
};
