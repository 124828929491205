import React from 'react';
import { Link } from 'react-router-dom';
import { STYLED_DATA_GRID } from '../../pages/AllForms';
import { GridArrowDownwardIcon } from '@mui/x-data-grid';
import moment from 'moment';
import PrintButton from '../FormTable/PrintButton';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { IconButton } from '@mui/material';
import DownloadButton from '../FormTable/DownloadButton';

const AllPDFForms = ({ entries, loading }) => {
  return (
    <>
      <div style={{ height: 300, width: '100%', minHeight: '800px' }}>
        <STYLED_DATA_GRID
          loading={loading}
          rows={entries.filter((e) => e.isPdf === true)}
          columns={[
            {
              field: 'submitted_at',
              headerName: 'Submitted At',
              width: 200,

              renderCell: (params) => {
                return moment
                  .utc(params.row.submitted_at)
                  .format('MM/DD/YY h:mma');
              },
            },
            {
              field: 'isPdf',
              headerName: 'PDF',
              width: 200,

              renderCell: (params) => <div>PDF Order</div>,
            },
            {
              field: 'billingCompany',
              headerName: 'Billing Company',
              width: 250,
              flex: 1,
            },
            {
              field: 'generatorCompany',
              headerName: 'Generator',
              width: 250,
            },
            {
              field: 'CleanlitesFacility',
              headerName: 'CRI Facility',
              width: 180,
            },
            {
              field: 'view',
              width: 100,
              disableColumnMenu: true,
              sortable: false,
              filterable: false,
              headerAlign: 'center',
              renderHeader: () => <>View</>,
              align: 'center',
              renderCell: (params) => (
                <Link to={`/forms/${params.row.id}`}>
                  <IconButton>
                    <RemoveRedEyeIcon color="secondary" />
                  </IconButton>
                </Link>
              ),
            },
            {
              field: 'print',
              headerName: 'Column 2',
              renderHeader: () => <>Print</>,
              headerAlign: 'center',
              disableColumnMenu: true,
              sortable: false,
              filterable: false,
              align: 'center',
              renderCell: (params) => {
                return <PrintButton uuid={params.row.id} isUploaded={true} />;
              },
            },
            {
              field: 'download',
              width: 120,
              disableColumnMenu: true,
              sortable: false,
              filterable: false,
              headerAlign: 'center',
              renderHeader: () => <>Download</>,
              align: 'center',
              renderCell: (params) => (
                <DownloadButton params={params} isPdf={true} />
              ),
            },
          ]}
        />
      </div>
    </>
  );
};

export default AllPDFForms;
